import React, { useEffect, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
// import { SubTab, SubInput } from "./TabNInput";
// import { DevTool } from "@hookform/devtools";
import { useSelector } from "react-redux";

import { API } from "../../constant/api";
import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Select from "react-select";
import {
  Input,
  RenderMultiLangInputFields,
  SubmitButton,
  CKEditorInput,
  ReactSelectInputTwo,
} from "../Form/Form";
import {
  formatDeliveryPriceData,
  generateRandomString,
  getLanguageId,
  getValueInLanguage,
} from "../../util/helper";

const SubTab = ({ name, index, image }) => {
  return (
    <li className={`nav-item ${index > 0 ? "mr-3" : ""}`}>
      <a
        className={`nav-link ${index === 0 ? "active" : ""}`}
        data-toggle="tab"
        href={`#kt_apps_contacts_view_tab_${index}`}
      >
        <>
          {false && image && (
            <span className="symbol symbol-20 mr-3">
              <img src={`${API.PORT}/${image}`} alt="" />
            </span>
          )}
          <span className="nav-text">{name}</span>
        </>
      </a>
    </li>
  );
};

const SubInput = ({ index, errors, register, required, InputFields, code }) => {
  return (
    <div
      className={`tab-pane ${index === 0 ? "active" : ""}`}
      id={`kt_apps_contacts_view_tab_${index}`}
      role="tabpanel"
    >
      <RenderMultiLangInputFields
        InputFields={InputFields}
        errors={errors}
        register={register}
        required={required}
        code={code}
      />

      <div className="row"></div>
    </div>
  );
};

const Edit = (props) => {
  const { countryId } = props.match.params;
  const ckEditorRef = useRef();
  const [isCkContent, setIsCkContent] = useState(false);

  const [langDataIds, setLangDataIds] = useState({});
  //const [images, setImages] = useState(null);

  const { languages } = useSelector((state) => state.setting);
  const history = useHistory();
  const [stateList, setStateList] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    getValues,
    clearErrors,
    reset,
    setError,
    control,
    watch,
  } = useForm();

  const {
    fields: priceFields,
    append: appendPrice,
    remove: removePrice,
  } = useFieldArray({
    control,
    name: "priceRanges", // Name for delivery price ranges
  });

  const {
    fields: stateFields,
    append: appendState,
    remove: removeState,
  } = useFieldArray({
    control,
    name: "stateDeliveryPrices", // Name for state delivery details
  });

  const { response: responseGetSection, request: requestGetSection } =
    useRequest();

  const { response, request } = useRequest();
  const { request: reqActiveStates, response: resActiveStates } = useRequest();

  useEffect(() => {
    requestGetSection("GET", `shipping-country/shipping-price/${countryId}`);

    document.title = "Shipping Price - Single Vendor";
    reqActiveStates("GET", `state/active?country_id=${countryId}`);
  }, []);

  useEffect(() => {
    if (!resActiveStates) return;
    let data = resActiveStates.states.map((state) => {
      return {
        label: state.name,
        value: state.id,
      };
    });
    //console.log(data);
    setStateList(data);
  }, [resActiveStates]);

  useEffect(() => {
    if (!responseGetSection) return;
    if (!responseGetSection.priceRange.length) return;
    let priceRange = responseGetSection.priceRange;
    priceRange = priceRange.map((range) => {
      return {
        min: range.order_price_from,
        max: range.order_price_to,
      };
    });
    //console.log("pr", priceRange);
    let deliveryPriceDetails = responseGetSection.stateDeliveryDetails;
    deliveryPriceDetails = formatDeliveryPriceData(deliveryPriceDetails);
    //console.log(deliveryPriceDetails);
    const resetObj = {};
    resetObj.priceRanges = priceRange;
    resetObj.stateDeliveryPrices = deliveryPriceDetails;
    reset(resetObj);
    updateStateList();
  }, [responseGetSection]);

  useEffect(() => {
    if (response) {
      toast.success(response.message);
      history.push("/shipping-country");
    }
  }, [response]);

  useEffect(() => {
    if (!responseGetSection || resActiveStates) return;
    if (!responseGetSection.stateDeliveryDetails.length) return;
    updateStateList();
  }, [responseGetSection, resActiveStates]);

  const onSubmitNew = (data) => {
    const transformedData = {
      priceRanges: data.priceRanges.map(({ min, max }) => ({
        min: Number(min),
        max: Number(max),
      })),
      stateDeliveryPrices: data.stateDeliveryPrices.map(
        ({ name, deliveryPrices }) => ({
          name,
          deliveryPrices: deliveryPrices.map((price) => Number(price)),
        })
      ),
    };

    const priceRanges = transformedData.priceRanges;
    //console.log(priceRanges);

    const invalidRanges = priceRanges.filter((range) => range.min >= range.max);
    if (invalidRanges.length > 0) {
      toast.error(
        "Some ranges have invalid values (minimum price must be less than maximum price)."
      );
      return;
    }

    const hasOverlaps = priceRanges.some((range1, index1) =>
      priceRanges.some(
        (range2, index2) =>
          index1 !== index2 &&
          range1.min < range2.max &&
          range1.max > range2.min
      )
    );

    if (hasOverlaps) {
      toast.error("There are overlapping price ranges. Please correct them.");
      return;
    }

    const payload = {
      countryId: countryId,
      priceRanges: transformedData.priceRanges,
      stateDeliveryPrices: transformedData.stateDeliveryPrices,
    };

    request("POST", "shipping-country/shipping-price", payload);
  };
  const handleonInstanceReady = (editor) => {
    ckEditorRef.current = editor;
  };

  const InputFields = [
    [
      {
        Component: Input,
        label: "Name",
        name: "name",
        registerFields: {
          required: true,
        },
        type: "text",
      },
    ],
  ];

  const addPriceRange = () => {
    appendPrice({ min: "", max: "" });

    // Append a new delivery price for each state to match the new price range
    //console.log(stateFields);

    stateFields.forEach((_, index) => {
      const currentDeliveryPrices = getValues(
        `stateDeliveryPrices.${index}.deliveryPrices`
      );
      const updatedDeliveryPrices = [...currentDeliveryPrices, 0];
      setValue(
        `stateDeliveryPrices.${index}.deliveryPrices`,
        updatedDeliveryPrices
      );
    });
  };

  const deletePriceRange = (delIndex) => {
    removePrice(delIndex);
    stateFields.forEach((_, index) => {
      //const currentDeliveryPrices = stateFields[index].deliveryPrices || [];
      //console.log("curr", currentDeliveryPrices);
      const currentDeliveryPrices = getValues(
        `stateDeliveryPrices.${index}.deliveryPrices`
      );

      currentDeliveryPrices.splice(delIndex, 1);
      setValue(
        `stateDeliveryPrices.${index}.deliveryPrices`,
        currentDeliveryPrices
      );
    });
  };
  //console.log(getValues(), "state.deliveryPrices");

  const updateStateList = () => {
    const selectStateIds = [];
    const stateDetails = getValues("stateDeliveryPrices");
    //console.log(stateDetails);
    stateDetails.forEach((state) => {
      if (state?.name?.value) selectStateIds.push(state?.name?.value);
    });
    //console.log(selectStateIds);
    const newStateList = stateList.map((state) => {
      return {
        ...state,
        isDisabled: selectStateIds.includes(state.value),
      };
    });
    setStateList(newStateList);
  };

  const addState = () => {
    updateStateList();
    appendState({
      name: "",
      deliveryPrices: Array(priceFields.length).fill(0),
    });
  };

  const onStateChangeHandler = () => {
    //console.log(getValues("stateDeliveryPriceDetails"));
    updateStateList();
  };

  const handleRemoveState = (index) => {
    removeState(index);
    updateStateList();
  };

  //console.log(errors, "err");

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Shipping Price"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/shipping-country", name: "Countries" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <form onSubmit={handleSubmit(onSubmitNew)}>
            <div className="card card-custom gutter-b price-overflow">
              <div className="card-header card-header-tabs-line">
                <h3 className="card-title">Manage Shipping Prices</h3>
              </div>
              <div className="card-body">
                <div>
                  <div className="table-responsive">
                    <table className="table table-bordered row_padding">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="main-heading main-width"
                            style={{
                              verticalAlign: "bottom",
                              minWidth: "400px",
                            }}
                          >
                            Order Amount
                          </th>

                          {stateFields.map((item, index) => (
                            <th
                              scope="col"
                              className="main-heading main-heading-th main-width"
                            >
                              <div className="form-inputSelectBox">
                                <div className="form-group mb-0">
                                  <ReactSelectInputTwo
                                    label="State"
                                    name={`stateDeliveryPrices.${index}.name`}
                                    registerFields={{
                                      required: true,
                                    }}
                                    options={stateList}
                                    control={control}
                                    errors={errors}
                                    handleChange={onStateChangeHandler}
                                  />

                                  {errors.stateDeliveryPrices?.[index]
                                    ?.name && (
                                    <span className="invalid-feedback">
                                      This field is required.
                                    </span>
                                  )}
                                  {/* <Select
                                    {...register(
                                      `stateDeliveryPrices.${index}.name`,
                                      {
                                        required: true,
                                      }
                                    )}
                                    options={setStateList}
                                  /> */}
                                  {/* <input
                                    type="text"
                                    placeholder="State Name"
                                    {...register(
                                      `stateDeliveryPrices.${index}.name`,
                                      {
                                        required: true,
                                      }
                                    )}
                                  /> */}
                                </div>
                                <a
                                  href="javascript:void(0)"
                                  className="btn btn-icon btn-light btn-hover-danger btn-sm removeCol coupon-state-remove"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title=""
                                  onClick={() => handleRemoveState(index)}
                                >
                                  <span className="svg-icon svg-icon-md svg-icon-danger">
                                    {/* SVG Icon */}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24px"
                                      height="24px"
                                      viewBox="0 0 24 24"
                                    >
                                      <g fill="none">
                                        <rect width="24" height="24"></rect>
                                        <path
                                          d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                          fill="#000000"
                                        />
                                        <path
                                          d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                          fill="#000000"
                                          opacity="0.3"
                                        />
                                      </g>
                                    </svg>
                                  </span>
                                </a>
                              </div>
                            </th>
                          ))}

                          <th scope="col">
                            <button
                              className="btn btn-primary add-state"
                              type="button"
                              onClick={addState}
                            >
                              Add State
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="main-tr">
                          <td className="main-tr-td">
                            <div style={{ paddingRight: "50px" }}>
                              {priceFields.map((item, index) => (
                                <div className="row">
                                  <div className="col-md-6 p-0">
                                    <div className="form-group ">
                                      <input
                                        className="form-control inputWidth60 input-box form-fields"
                                        type="number"
                                        placeholder="Min Price"
                                        {...register(
                                          `priceRanges.${index}.min`,
                                          {
                                            required: "min price is required",
                                            min: {
                                              value: 0,
                                              message:
                                                "discount price must be at least 0",
                                            },
                                          }
                                        )}
                                      />
                                      <div className="invalid-feedback invalid-feedback-error mt-0 shippin-price-range-error">
                                        {errors.priceRanges?.[index]?.min && (
                                          <span>
                                            {
                                              errors.priceRanges[index].min
                                                .message
                                            }
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6 p-0">
                                    <div className="form-group">
                                      <input
                                        className="form-control inputWidth60 input-box form-fields"
                                        type="number"
                                        placeholder="Max Price"
                                        {...register(
                                          `priceRanges.${index}.max`,
                                          {
                                            required: "max price is required",
                                            min: {
                                              value: 0,
                                              message:
                                                "max price must be at least 0",
                                            },
                                          }
                                        )}
                                      />
                                      <div
                                        className="invalid-feedback invalid-feedback-error mt-2"
                                        style={{ bottom: "0px" }}
                                      >
                                        {errors.priceRanges?.[index]?.max && (
                                          <span>
                                            {
                                              errors.priceRanges[index].max
                                                .message
                                            }
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <a
                                    href="javascript:void(0)"
                                    className="btn btn-icon btn-light btn-hover-danger btn-sm removeRow"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title=""
                                    onClick={() => deletePriceRange(index)}
                                  >
                                    <span className="svg-icon svg-icon-md svg-icon-danger">
                                      {/* SVG Icon */}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                      >
                                        <g fill="none">
                                          <rect width="24" height="24"></rect>
                                          <path
                                            d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                            fill="#000000"
                                          />
                                          <path
                                            d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                            fill="#000000"
                                            opacity="0.3"
                                          />
                                        </g>
                                      </svg>
                                    </span>
                                  </a>
                                </div>
                              ))}
                            </div>
                          </td>

                          {stateFields.map((item, stateIndex) => {
                            return (
                              <td className="main-tr-td">
                                {getValues(
                                  `stateDeliveryPrices.${stateIndex}.deliveryPrices`
                                )?.map((_, id) => (
                                  <div className="">
                                    <div className="form-group">
                                      <input
                                        type="number"
                                        placeholder="Delivery Price *"
                                        {...register(
                                          `stateDeliveryPrices[${stateIndex}].deliveryPrices[${id}]`,
                                          {
                                            required: "price is required",
                                            min: {
                                              value: 0,
                                              message:
                                                "price must be at least 0",
                                            },
                                          }
                                        )}
                                        className="form-control input-box form-fields"
                                        data-is-required="1"
                                      />
                                      <div className="invalid-feedback invalid-feedback-error mt-2">
                                        {errors?.stateDeliveryPrices?.[
                                          stateIndex
                                        ]?.deliveryPrices?.[id] && (
                                          <span className="invalid-feedback">
                                            {
                                              errors?.stateDeliveryPrices[
                                                stateIndex
                                              ]?.deliveryPrices[id]?.message
                                            }
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </td>
                            );
                          })}
                          <td></td>
                        </tr>
                        <tr className="add-price-tr">
                          <td>
                            <button
                              onClick={addPriceRange}
                              className="btn btn-primary add-price"
                              type="button"
                            >
                              Add Price
                            </button>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex justify-content-between border-top mt-5 pt-10">
                    <div className="mr-2">
                      <button
                        type="submit"
                        className="btn btn-success font-weight-bold text-uppercase px-9 py-4 submit-btn"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* <DevTool control={control} /> */}
    </div>
  );
};

export default Edit;
