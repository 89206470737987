import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import moment from "moment";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {
  Input,
  SelectInput,
  RenderInputFields,
  SubmitButton,
  ReactSelectInput,
  PhoneNumber,
} from "../Form/Form";
import { API } from "../../constant/api";

const apiName = "user";
const titleSingular = "Customer";
const titlePlural = "Customers";

const Edit = (props) => {
  const { id } = props.match.params;
  const history = useHistory();
  const imgArray = ["image/png", "image/jpeg", "image/jpg"];

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm();

  const { response: responseFetchUser, request: requestFetchSeeker } =
    useRequest();
  const { response: responseTitle, request: requestTitle } = useRequest();

  const { response, request } = useRequest();
  const [titles, setTitles] = useState([]);
  const [image, setImage] = useState("");
  const [isProfilePicRemove, setIsProfilePicRemove] = useState(false);

  useEffect(() => {
    requestTitle("GET", "master/title-admin");
  }, []);

  useEffect(() => {
    if (id) {
      requestFetchSeeker("GET", `${apiName}/${id}`);
      document.title = `Edit ${titleSingular} - Affit Tool`;
    }
  }, [id]);

  useEffect(() => {
    if (responseTitle) {
      const { titles } = responseTitle;
      setTitles(titles);
    }
  }, [responseTitle]);

  useEffect(() => {
    if (responseFetchUser) {
      const {
        first_name,
        last_name,
        phone_number,
        phone_number_code,
        profile_picture,
        email,
        title,
      } = responseFetchUser.user;
      if (profile_picture) {
        setImage(`${API.PORT}/${profile_picture}`);
      }
      setValue("first_name", first_name);
      setValue("last_name", last_name);
      setValue("phone_number", `${phone_number_code}${phone_number}`);
      setValue("phone_number_code", phone_number_code);
      setValue("email", email);
      setValue("title", title);
    }
  }, [responseFetchUser]);

  useEffect(() => {
    if (response) {
      toast.success("Customer has been updated successfully.");
      history.push("/customers");
    }
  }, [response]);

  const displayImageHandler = (e) => {
    setImage(URL.createObjectURL(e.target.files[0]));
    setIsProfilePicRemove(false);
  };
  const handleRemoveMedia = () => {
    setIsProfilePicRemove(true);
    setValue("profile_picture", "");
    setImage("");
    document.getElementsByName("profile_picture")[0].value = "";
  };

  const onSubmit = (data) => {
    let {
      first_name,
      last_name,
      email,
      phone_number,
      phone_number_code,
      profile_picture,
      title,
    } = data;
    if (email) {
      email = email.toLowerCase();
    }

    const withoutCountryCode = phone_number.replace(`${phone_number_code}`, "");
    const formData = new FormData();
    formData.append("last_name", last_name);
    formData.append("first_name", first_name);
    formData.append("email", email);
    formData.append("phone_number", withoutCountryCode);
    formData.append("phone_number_code", phone_number_code);
    formData.append("id", id);
    formData.append("isProfilePicRemove", isProfilePicRemove);
    formData.append("title", title);

    if (profile_picture && profile_picture[0]) {
      const profilePicType = profile_picture[0].type;
      if (!imgArray.includes(profilePicType)) {
        toast.error("Please select inage only in profile pic");
        return;
      } else {
        formData.append("profile-picture", profile_picture[0]);
      }
    }

    request("PUT", apiName, formData);
  };

  const InputFields = [
    [
      {
        Component: SelectInput,
        label: "Title",
        name: "title",
        registerFields: {
          required: true,
        },
        children: (
          <>
            <option value="">{"Select an option"}</option>
            {titles.map((data) => (
              <option value={data.parent_id} key={data.id}>
                {" "}
                {data.code}
              </option>
            ))}
          </>
        ),
      },
      {
        Component: Input,
        label: "First Name",
        type: "text",
        name: "first_name",
        registerFields: {
          required: true,
          setValueAs: (v) => v.trim(),
          // pattern: /^[A-Za-z ]+$/,
        },
        // registerFieldsFeedback: {
        //   pattern: "First name can only contain letters.",
        // },
      },
      {
        Component: Input,
        label: "Last Name",
        type: "text",
        name: "last_name",
        registerFields: {
          required: true,
          setValueAs: (v) => v.trim(),
          // pattern: /^[A-Za-z ]+$/,
        },
        // registerFieldsFeedback: {
        //   pattern: "Last name can only contain letters.",
        // },
      },
      {
        Component: Input,
        label: "Email",
        type: "email",
        name: "email",
        registerFields: {
          required: true,
          pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
          setValueAs: (v) => v.trim(),
        },
        registerFieldsFeedback: {
          pattern: "The email field must be a valid email address.",
        },
      },

      {
        Component: PhoneNumber,
        label: "Contact",
        type: "number",
        name: "phone_number",
        control: control,
        setValue: setValue,
        registerFields: {
          required: true,
          pattern: {
            value: /^[0-9][0-9]{7,14}$/,
            // message: "Please enter valid 8-15 digit contact number.",
          },
        },
        registerFieldsFeedback: {
          pattern: "Please enter valid 8-15 digit contact number.",
        },
      },

      {
        Component: Input,
        label: "Profile Pic",
        type: "file",
        name: "profile_picture",
        registerFields: {
          required: false,
          setValueAs: (v) => v.trim(),
        },
        registerFieldsFeedback: {
          // pattern: "The zip code field must be a valid zip code.",
        },
        handleMedia: displayImageHandler,
        isMedia: true,
        accept: ".png, .jpg, .jpeg",
        control,
        children: image && (
          <>
            <img
              src={image}
              width={150}
              height={100}
              alt=""
              style={{ cursor: "pointer", marginBottom: "10px" }}
              data-fancybox
            />
            <Link to="#" onClick={handleRemoveMedia} className="mx-3">
              Remove
            </Link>
          </>
        ),
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Edit Customer"
        links={[
          { to: "/", name: "Dashboard" },
          {
            to: { pathname: "/customers" /*backPageNum: page */ },
            name: "Back To Customers",
          },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card card-custom">
            <div class="card-header">
              <h3 class="card-title">Edit Customer</h3>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-xl-12">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Update"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
