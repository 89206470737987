import { useEffect } from "react";
import { API } from "../../../constant/api";
import useRequest from "../../../hooks/useRequest";
import "../product.css";
import { toast } from "react-toastify";

const EditImageUploader = ({
  productId,
  images,
  setImages,
  coverImage,
  setCoverImage,
}) => {
  const { request, response } = useRequest();
  const { request: deleteImageRequest, response: deleteImageResponse } =
    useRequest();
  const {
    request: requestUpdateCoverImage,
    response: responseUpdateCoverImage,
  } = useRequest();

  const maxLength = 5;

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const newImages = [];

    files.forEach((file) => {
      if (!file.type.match("image.*")) return; // Check if file is an image
      if (newImages.length >= maxLength) return; // Limit to maxLength
      newImages.push(file);
    });

    if (!coverImage) setCoverImage(newImages[0]);

    const formData = new FormData();

    newImages.forEach((image, index) => {
      //console.log("im", image);
      formData.append("images", image);
    });

    request("POST", `product/${productId}/product-images`, formData);

    /* setImages((prevImages) => {
      const combinedImages = [...prevImages, ...newImages];
      return combinedImages.slice(0, maxLength); // Ensure we do not exceed maxLength
    }); */
  };

  useEffect(() => {
    if (!response) return;
    //console.log(response);
    setImages([coverImage, ...response?.images?.map((img) => img.image)]);
  }, [response]);

  const handleImageRemove = (deleteImage) => {
    if (coverImage && coverImage == deleteImage) {
      return toast.error("can't delete cover photo");
    }
    const newImages = [...images].filter((file) => file !== deleteImage);
    setImages(newImages);
    const payload = { deleteImage: deleteImage };
    deleteImageRequest(
      "DELETE",
      `product/${productId}/product-images`,
      payload
    );
  };

  const makeCoverImage = (file) => {
    setCoverImage(file);
    requestUpdateCoverImage("PUT", `product/${productId}/update-cover-image`, {
      new_cover_image: file,
      old_cover_image: coverImage,
    });
  };

  return (
    <div className="upload__box">
      <div className="upload__btn-box">
        <label className="upload__btn">
          <p>Drag and drop or Upload from your computer</p>
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            className="upload__inputfile"
            data-max_length={maxLength}
          />
        </label>
      </div>
      <div className="upload__img-wrap">
        {images.map((image, index) => {
          const imageUrl = `'${API.PORT}/${image}'`;
          return (
            <div key={index} className="upload__img-box">
              <div
                style={{ backgroundImage: `url(${imageUrl})` }}
                className="img-bg"
              >
                {coverImage == image && (
                  <h3 class="cover_image_add image_coverdesgin">Cover Photo</h3>
                )}

                <div
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Make cover photo"
                  className="upload__img-icon upload__img-cover"
                  onClick={() => makeCoverImage(image)}
                ></div>
                <div
                  className="upload__img-icon upload__img-close"
                  onClick={() => handleImageRemove(image)}
                ></div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EditImageUploader;
