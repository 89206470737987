import React, { useState, useEffect, useRef } from "react";
import { InfoSvg } from "../../util/Svg";

export default function TooltipComponent({ value = "" }) {
  const [position, setPosition] = useState("tooltipLeft");
  const tooltipRef = useRef(null);
  const buttonRef = useRef(null);

  const updatePosition = () => {
    setPosition("tooltipLeft");
  };

  useEffect(() => {
    updatePosition();
    window.addEventListener("resize", updatePosition);
    return () => window.removeEventListener("resize", updatePosition);
  }, [value]);

  return (
    <div className="tooltipBox">
      <button className="tooltipBoxBtn" ref={buttonRef}><InfoSvg /></button>
      <div className={`tooltipShowBox ${position}`} ref={tooltipRef}>
        {value}
        <div className={`tooltipArrow ${position}`} />
      </div>
    </div>
   
  );
}
