import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Pagination from "../Pagination/Pagination";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Table from "../Table/Table";
import { SearchInput, SearchSubmitButton, SelectInput } from "../Form/Form";

const OBJ_TABLE = {
  name: "name",
  image: "image",
  category: "categoryHierarchy",
  price: "price",
  "newly launched": "new_arrival",
  "created on": "created_at",
  status: "isActive",
};

const searchQueryHandler = (
  page,
  per_page,
  sortBy,
  order,
  product = "",

  parent_category = "",
  category_level_2 = "",
  category_level_3 = "",
  new_arrival
) => {
  if (sortBy.length > 0) {
    if (sortBy == "created on") {
      sortBy = "created_at";
    }
    if (sortBy == "category") {
      sortBy = "categoryHierarchy";
    }
    if (sortBy == "newly launched") {
      sortBy = "new_arrival";
    }

    if (sortBy == "status") sortBy = "is_active";
  } else {
    sortBy = "created_at";
  }

  order = order.length > 0 ? order : "desc";

  return `product/all?page=${page}&per_page=${per_page}&sort_by=${sortBy}&order=${order}&product=${product}&parent_category=${parent_category}&category_level_2=${category_level_2}&category_level_3=${category_level_3}&new_arrival=${
    new_arrival ?? 0
  }`;
};

const ViewAll = () => {
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(10);
  const [parentCategoryInput, setParentCategoryInput] = useState([]);

  const [categoryL2Input, setCategoryL2Input] = useState([]);
  const [categoryL3Input, setCategoryL3Input] = useState([]);
  const [perPage, setPerPage] = useState(1);
  const [currentSort, setCurrentSort] = useState({
    sortBy: "created on",
    order: "desc",
  });

  const MySwal = withReactContent(Swal);

  const { records_per_page } = useSelector((state) => state.setting);
  const { roleId, permission } = useSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    setValue,
    getValues,
    watch,
  } = useForm();

  const { request, response } = useRequest();
  const { request: requestChangeStatus, response: responseChangeStatus } =
    useRequest();
  const { request: requestDelete, response: responseDelete } = useRequest();
  const { request: getParentCategory, response: parentCategoryResponse } =
    useRequest();
  const { request: getCategoryLevel2, response: categoryLevel2Response } =
    useRequest();
  const { request: getCategoryLevel3, response: categoryLevel3Response } =
    useRequest();
  const {
    loading,
    request: requestChecked,
    response: responseChecked,
  } = useRequest("NotLoading");

  useEffect(() => {
    if (records_per_page) {
      setPerPage(records_per_page);
      request(
        "GET",
        searchQueryHandler(
          1,
          records_per_page,
          currentSort.sortBy,
          currentSort.order
        )
      );
    }
    document.title = "Product- Single Vendor";
  }, [records_per_page]);

  useEffect(() => {
    if (response) {
      setProducts(response.products);
      setTotalDocuments((prev) => response.totalDocuments ?? prev);
    }
  }, [response]);

  useEffect(() => {
    if (responseChangeStatus) {
      const { id, newStatus } = responseChangeStatus;

      const oldProducts = [...products];
      const indexToChange = oldProducts.findIndex(
        (product) => product.id == id
      );
      oldProducts[indexToChange].isActive = newStatus ? true : false;
      oldProducts[indexToChange].is_active = newStatus;
      toast.success(responseChangeStatus.message);
      setProducts(oldProducts);
    }
  }, [responseChangeStatus]);

  useEffect(() => {
    if (responseChecked) {
      const { id, new_arrival } = responseChecked;
      //console.log(id, newStatus)

      const oldProducts = [...products];
      const indexToChange = oldProducts.findIndex(
        (product) => product.id == id
      );
      // console.log("oldProducts",oldProducts)
      // console.log("indexToChange",indexToChange)
      oldProducts[indexToChange].new_arrival = new_arrival ? 1 : 0;
      //  oldProducts[indexToChange].new_arrival = new_arrival;
      //  console.log( oldProducts[indexToChange].new_arrival = new_arrival)
      // toast.success(responseChecked.message);
      setProducts(oldProducts);
    }
  }, [responseChecked]);

  useEffect(() => {
    if (responseDelete) {
      const { id } = responseDelete;
      let newProducts = [...products];
      newProducts = newProducts.filter((product) => product.id != id);
      setProducts(newProducts);

      setTotalDocuments(totalDocuments - 1);
      // const pageCount = Math.ceil((totalDocuments) / perPage)

      // if(pageCount == page){
      //   const { question } = getValues();
      //   const newPage = page - 1;
      //   setPage(newPage);
      //   request(
      //     "GET",
      //     searchQueryHandler(
      //       newPage,
      //       perPage,
      //       currentSort.sortBy,
      //       currentSort.order,
      //       question
      //     )
      //   );
      // }
      toast.success("Product has been deleted successfully.");
    }
  }, [responseDelete]);

  useEffect(() => {
    getParentCategory("GET", `category/activeCategories?parent_id=${0}`);
  }, []);

  useEffect(() => {
    if (!parentCategoryResponse) return;
    setParentCategoryInput(parentCategoryResponse.categories);
  }, [parentCategoryResponse]);

  const changeParentCategory = (value) => {
    //getCategoryLevel2()
    //console.log(value);
    setCategoryL2Input([]);
    setCategoryL3Input([]);
    setValue("category_level_2", "");
    setValue("category_level_3", "");
    if (!value) return;
    getCategoryLevel2("GET", `category/activeCategories?parent_id=${value}`);
  };

  const changeCategoryL2 = (value) => {
    setCategoryL3Input([]);
    setValue("category_level_3", "");
    if (!value) return;
    getCategoryLevel3("GET", `category/activeCategories?parent_id=${value}`);
  };

  const changeCategoryL3 = (value) => {
    //console.log(value);
    const inputVarientList = [];
    //setColorsList([]);
    //setSizesList([]);
    //setValue("colors", "");
    //setValue("sizes", "");
    //setValue("variants", inputVarientList);
    //setInputVariants(inputVarientList);
    //setShowAddVarient(inputVarientList.length ? true : false);
    if (!value) return;

    //getActiveColors("GET", `category/${value}/active-colors`);
    //getActiveSizes("GET", `category/${value}/active-sizes`);
  };

  useEffect(() => {
    if (!categoryLevel2Response) return;
    //console.log(categoryLevel2Response);
    setCategoryL2Input(categoryLevel2Response.categories);
  }, [categoryLevel2Response]);

  useEffect(() => {
    if (!categoryLevel3Response) return;
    //console.log(categoryLevel2Response);
    setCategoryL3Input(categoryLevel3Response.categories);
  }, [categoryLevel3Response]);

  const fetchMoreData = ({ selected }) => {
    setProducts([]);
    //product which is enter on search input
    const { product, parent_category, category_level_2, category_level_3 } =
      getValues();

    setPage(selected + 1);
    request(
      "GET",
      searchQueryHandler(
        selected + 1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        product,
        parent_category,
        category_level_2,
        category_level_3
      )
    );
  };

  const deleteHandler = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You want to delete this product?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        requestDelete("DELETE", "product", { id });
      } else if (result.isDismissed) {
      }
    });
  };

  const onSearchHandler = (data) => {
    const { product, parent_category, category_level_2, category_level_3 } =
      getValues();
    console.log(
      "pro",
      product,
      parent_category,
      category_level_2,
      category_level_3
    );
    request(
      "GET",
      searchQueryHandler(
        1,
        perPage,
        currentSort.sortBy,
        currentSort.order,
        product,
        parent_category,
        category_level_2,
        category_level_3
      )
    );
    setPage(1);
  };

  const onResetHandler = (e) => {
    e.preventDefault();
    resetField("product");
    resetField("parent_category");
    resetField("category_level_2");
    resetField("category_level_3");
    request(
      "GET",
      searchQueryHandler(1, perPage, currentSort.sortBy, currentSort.order)
    );
    setPage(1);
  };

  const perPageChangeHandler = (event) => {
    const { product, parent_category, category_level_2, category_level_3 } =
      getValues();

    request(
      "GET",
      searchQueryHandler(
        1,
        event.target.value,
        currentSort.sortBy,
        currentSort.order,
        product,
        parent_category,
        category_level_2,
        category_level_3
      )
    );
    setPage(1);

    setPerPage(event.target.value);
  };

  const sortingHandler = (sortBy) => {
    const { product, parent_category, category_level_2, category_level_3 } =
      getValues();

    if (currentSort.sortBy == sortBy) {
      const newOrder = currentSort.order === "asc" ? "desc" : "asc";
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          newOrder,
          product,
          parent_category,
          category_level_2,
          category_level_3
        )
      );
      setCurrentSort({ sortBy, order: newOrder });
    } else {
      request(
        "GET",
        searchQueryHandler(
          page,
          perPage,
          sortBy,
          "desc",
          product,
          parent_category,
          category_level_2,
          category_level_3
        )
      );
      setCurrentSort({ sortBy, order: "desc" });
    }
  };

  const changeStatusHandler = (id) => {
    const productsNew = products.find((product) => product.id == id);

    const status = productsNew.is_active == 1 ? 0 : 1;

    MySwal.fire({
      title: "Are you sure?",
      text: "Want to change this status?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change it",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        requestChangeStatus("PUT", "product/status", {
          id,
          status,
        });
      } else if (result.isDismissed) {
      }
    });
  };

  const onCheckStatus = (id, new_arrival) => {
    const productsNew = products.find((product) => product.id == id);
    //  const new_arrival = productsNew?.new_arrival == 0 ? 1 : 0;
    requestChecked("Put", "product/arrival", {
      id: productsNew?.id,
      new_arrival,
    });
  };

  //search input field
  const InputFields = [
    {
      label: "Product",
      name: "product",
      required: false,
    },
    {
      isSelectInput: true,
      label: "Parent Category",
      name: "parent_category",
      required: false,
      onChange: changeParentCategory,
      children: (
        <>
          <option value={""}>All</option>,
          {parentCategoryInput.map((category) => (
            <option value={category.id}>{category.name}</option>
          ))}
        </>
      ),
    },
    {
      isSelectInput: true,
      label: "Category Level 2",
      name: "category_level_2",
      required: false,
      onChange: changeCategoryL2,
      children: (
        <>
          <option value={""}>Select Category</option>,
          {categoryL2Input.map((category) => (
            <option value={category.id}>{category.name}</option>
          ))}
        </>
      ),
    },
    {
      isSelectInput: true,
      label: "Category Level 3",
      name: "category_level_3",
      required: false,
      onChange: changeCategoryL3,
      children: (
        <>
          <option value={""}>Select Category</option>,
          {categoryL3Input.map((category) => (
            <option value={category.id}>{category.name}</option>
          ))}
        </>
      ),
    },
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb title="Products" links={[{ to: "/", name: "Dashboard" }]} />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="row">
            <div className="col-12">
              <div className="card card-custom card-stretch card-shadowless">
                <div className="card-header">
                  <div className="card-title"></div>
                  <div className="card-toolbar">
                    <a
                      /*href="#!"*/
                      className="btn btn-primary dropdown-toggle mr-2"
                      data-toggle="collapse"
                      data-target="#collapseOne6"
                    >
                      Search
                    </a>

                    <Link to="/product/add" className="btn btn-primary">
                      Add New Product
                    </Link>
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="accordion accordion-solid accordion-toggle-plus"
                    id="accordionExample6"
                  >
                    <div
                      id="collapseOne6"
                      className="collapse"
                      data-parent="#accordionExample6"
                    >
                      <div>
                        <form
                          onSubmit={handleSubmit(onSearchHandler)}
                          className="kt-form kt-form--fit mb-0"
                        >
                          <div className="row mb-6">
                            {InputFields.map((inputMain, index) => (
                              <SearchInput
                                key={index}
                                {...inputMain}
                                errors={errors}
                                register={register}
                              />
                            ))}
                          </div>

                          <SearchSubmitButton
                            handleSubmit={handleSubmit}
                            onSearchHandler={onSearchHandler}
                            onResetHandler={onResetHandler}
                          />
                        </form>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="dataTables_wrapper faq-table">
                    <Table
                      currentSort={currentSort}
                      sortingHandler={sortingHandler}
                      mainData={products}
                      register={register}
                      setValue={setValue}
                      watch={watch}
                      onCheckStatus={onCheckStatus}
                      tableHeading={Object.keys(OBJ_TABLE)}
                      tableData={Object.values(OBJ_TABLE)}
                      links={[
                        {
                          isLink: false,
                          name: "Deactivate",
                          click: changeStatusHandler,
                          title: "Click To Activate",
                          key: "12_42",
                        },
                        {
                          isLink: false,
                          name: "Activate",
                          click: changeStatusHandler,
                          title: "Click To Deactivate",
                          key: "12_42",
                        },
                        {
                          isLink: true,
                          to: "/product/edit",
                          name: "Edit",
                          extraData: true,
                          key: "12_43",
                        },
                        {
                          isLink: false,
                          name: "Delete",
                          click: deleteHandler,
                          key: "12_44",
                        },
                        {
                          isLink: true,
                          to: "/product-details",
                          name: "View",
                          extraData: true,
                          key: "12_45",
                        },
                        {
                          isLink: true,
                          title: "stock history",
                          name: "Logs",
                          to: "/product/stock-history",
                          extraData: true,
                          key: "12_46",
                        },
                      ]}
                      onlyDate={{
                        created_at: "date",
                        startDate: "dateTime",
                        endDate: "dateTime",
                      }}
                      dontShowSort={["question", "answer"]}
                    />

                    {perPage !== 0 && (
                      <Pagination
                        page={page}
                        totalDocuments={totalDocuments}
                        getNewData={fetchMoreData}
                        perPage={perPage}
                        defaultPerPage={records_per_page}
                        perPageChangeHandler={perPageChangeHandler}
                        currentDocLength={products.length}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAll;
