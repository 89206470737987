import { useState } from "react";
import "../product.css";

const ImageUploader = ({ images, setImages, coverImage, setCoverImage }) => {
  const maxLength = 20;

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const newImages = [];

    files.forEach((file) => {
      if (!file.type.match("image.*")) return; // Check if file is an image
      if (newImages.length >= maxLength) return; // Limit to maxLength

      newImages.push(file);
    });

    if (!coverImage) setCoverImage(newImages[0]);

    setImages((prevImages) => {
      const combinedImages = [...prevImages, ...newImages];
      return combinedImages.slice(0, maxLength); // Ensure we do not exceed maxLength
    });
  };

  const handleImageRemove = (fileName) => {
    let newCoverImage = coverImage;
    if (coverImage && coverImage.name == fileName) newCoverImage = undefined;
    const newImages = [...images].filter((file) => file.name !== fileName);
    setImages(newImages);
    if (!newCoverImage && newImages.length) {
      newCoverImage = newImages[0];
    }
    setCoverImage(newCoverImage);
  };

  const makeCoverImage = (file) => {
    //console.log("hh", file);
    setCoverImage(file);
  };

  return (
    <div className="upload__box">
      <div className="upload__btn-box">
        <label className="upload__btn">
          <p>Drag and drop or Upload from your computer</p>
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            className="upload__inputfile"
            data-max_length={maxLength}
          />
        </label>
      </div>
      <div className="upload__img-wrap">
        {images.map((file, index) => {
          const imageUrl = URL.createObjectURL(file);
          return (
            <div key={index} className="upload__img-box">
              <div
                style={{ backgroundImage: `url(${imageUrl})` }}
                data-file={file.name}
                className="img-bg"
              >
                {coverImage == file && (
                  <h3 class="cover_image_add image_coverdesgin">Cover Photo</h3>
                )}

                <div
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Make cover photo"
                  className="upload__img-icon upload__img-cover"
                  onClick={() => makeCoverImage(file)}
                ></div>
                <div
                  className="upload__img-icon upload__img-close"
                  onClick={() => handleImageRemove(file.name)}
                ></div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ImageUploader;
