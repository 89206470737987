export const getValueInLanguage = (data, langId) => {
  return data.find((d) => d.language_id === langId);
};

export const getLanguageId = (languages, langCode = "en") => {
  return languages.find((language) => language.code === langCode)?.id || 1;
};

export const generateRandomString = (length) => {
  /* const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"; */
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }
  return result;
};

export const formatDeliveryPriceData = (data) => {
  const result = Object.values(
    data.reduce((acc, item) => {
      const key = item.state_id;

      if (!acc[key]) {
        acc[key] = {
          name: { label: item.name, value: item.state_id },
          deliveryPrices: [],
        };
      }

      acc[key].deliveryPrices.push(item.shipping_price.toString());

      return acc;
    }, {})
  );
  return result;
};

export const filterVariants = (old_data, new_data) => {
  const add = new_data.filter(
    (newItem) =>
      !old_data.some(
        (oldItem) =>
          oldItem.color_id === newItem.colorId &&
          oldItem.size_id === newItem.sizeId
      )
  );

  // Find elements in old_data that are not in new_data
  const deleteArr = old_data.filter(
    (oldItem) =>
      !new_data.some(
        (newItem) =>
          oldItem.color_id === newItem.colorId &&
          oldItem.size_id === newItem.sizeId
      )
  );
  return [add, deleteArr];
};
