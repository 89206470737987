import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
// import { SubTab, SubInput } from "./TabNInput";
// import { DevTool } from "@hookform/devtools";
import { useSelector } from "react-redux";

import { API } from "../../constant/api";
import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Moment from "moment";

import {
  Input,
  RenderMultiLangInputFields,
  SubmitButton,
  CKEditorInput,
  ReactSelectInput,
  ReactSelectInputTwo,
} from "../Form/Form";
import { generateRandomString } from "../../util/helper";

const SubTab = ({ name, index, image }) => {
  return (
    <li className={`nav-item ${index > 0 ? "mr-3" : ""}`}>
      <a
        className={`nav-link ${index === 0 ? "active" : ""}`}
        data-toggle="tab"
        href={`#kt_apps_contacts_view_tab_${index}`}
      >
        <>
          {false && image && (
            <span className="symbol symbol-20 mr-3">
              <img src={`${API.PORT}/${image}`} alt="" />
            </span>
          )}
          <span className="nav-text">{name}</span>
        </>
      </a>
    </li>
  );
};

const SubInput = ({ index, errors, register, required, InputFields, code }) => {
  return (
    <div
      className={`tab-pane ${index === 0 ? "active" : ""}`}
      id={`kt_apps_contacts_view_tab_${index}`}
      role="tabpanel"
    >
      <RenderMultiLangInputFields
        InputFields={InputFields}
        errors={errors}
        register={register}
        required={required}
        code={code}
      />

      <div className="row"></div>
    </div>
  );
};

const Add = () => {
  const ckEditorRef = useRef();
  const { languages } = useSelector((state) => state.setting);
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    getValues,
    clearErrors,
    control,
    setError,
  } = useForm({
    defaultValues: {
      discount_type: "flat",
      coupon_code: generateRandomString(10),
    },
  });

  const [categoryHierarchyList, setCategoryHierarchyList] = useState([]);
  const [productList, setProductList] = useState([]);
  const { request: reqCategoryHierarchy, response: resCategoryHierarchy } =
    useRequest();

  const { request: reqProduct, response: resProduct } = useRequest();

  const { response, request } = useRequest();

  useEffect(() => {
    document.title = "Add-Coupon - Single Vendor";
    reqCategoryHierarchy("GET", `category/activeCategoriesHierarchy`);
    reqProduct("GET", "product/active");
    //setValue("tax_type", "Fixed");
  }, []);

  useEffect(() => {
    if (!resCategoryHierarchy) return;
    const data = resCategoryHierarchy.categories.map((category) => {
      return {
        label: category.category_hierarchy,
        value: category.category_level3_id,
      };
    });
    setCategoryHierarchyList(data);
  }, [resCategoryHierarchy]);

  useEffect(() => {
    if (!resProduct) return;
    const data = resProduct.products.map((product) => {
      return {
        label: product.name,
        value: product.id,
      };
    });
    setProductList(data);
  }, [resProduct]);

  useEffect(() => {
    if (response) {
      toast.success(response.message);
      history.push(`/coupon`);
    }
  }, [response]);

  const onSubmitNew = (data) => {
    console.log("data:::::::::::::", data);

    const { start_time, end_time } = data;

    if (start_time && end_time) {
      if (Moment(start_time).isAfter(end_time)) {
        toast.error("Please enter valid date range");
        return;
      }
    }

    const payload = {
      coupon_code: data.coupon_code,
      start_time: data.start_time,
      end_time: data.end_time,
      number_of_usage: data.number_of_usage,
      number_of_usage_per_person: data.number_of_usage_per_person,
      mininum_product_price: data.mininum_product_price,
      maxinum_product_price: data.maxinum_product_price,
      discount_type: data.discount_type,
      offer_amount: data.offer_amount,
      categories: data.categories || [],
      products: data.products || [],
    };

    /*  const subData = [];

    for (let i = 0; i < languages.length; i++) {
      const id = languages[i].id;
      const code = languages[i].code;
      subData.push({
        language_id: id,
        name: data[`name-${code}`],
      });
    }

    payload.subData = subData; */
    //console.log(payload);

    request("POST", "coupon", payload);
  };

  //   const InputFields = [
  //     [
  //       {
  //         Component: Input,
  //         label: "Name",
  //         name: "name",
  //         registerFields: {
  //           required: true,
  //         },
  //         type: "text",
  //       },
  //     ],
  //   ];

  const handleDateChange = (e, name) => {};

  const InputGroup1 = [
    {
      Component: Input,
      label: "Discount Code",
      name: "coupon_code",
      registerFields: {
        required: true,
        onChange: (e) => {
          const uppercaseValue = e.target.value.toUpperCase();
          setValue("coupon_code", uppercaseValue);
        },
      },
      type: "text",
    },
    {
      Component: Input,
      label: "Start Time",
      name: "start_time",
      isDate: true,

      registerFields: {
        required: true,
      },
      clearErrors,
      control,
      type: "date",
      min: new Date().toISOString().split("T")[0],
      //handleDateChange: handleDateChange,
    },
    {
      Component: Input,
      label: "End Time",
      name: "end_time",
      isDate: true,
      clearErrors,
      control,
      registerFields: {
        required: true,
      },
      //handleDateChange: handleDateChange,
      // otherRegisterFields: {
      //   manual: true,
      //   feedback: "'To Date' cannot be smaller than 'From Date'",
      // },
      type: "date",
      min: new Date().toISOString().split("T")[0],
    },
    {
      Component: Input,
      label: "Number Of Usage",
      name: "number_of_usage",
      registerFields: {
        required: true,
        min: 0,
      },
      type: "number",
    },
    {
      Component: Input,
      label: "Number Of Usage Per Person",
      name: "number_of_usage_per_person",
      registerFields: {
        required: true,
        min: 0,
      },
      type: "number",
    },
    {
      Component: Input,
      label: "Mininum Product Price",
      name: "mininum_product_price",
      registerFields: {
        required: true,
        min: 0,
      },
      type: "number",
    },
    {
      Component: Input,
      label: "Maximum Product Price",
      name: "maxinum_product_price",
      registerFields: {
        required: true,
        min: 0,
      },
      type: "number",
    },
  ];

  const InputGroup2 = [
    {
      Component: Input,
      label: "Offer Amount",
      name: "offer_amount",
      registerFields: {
        required: true,
        min: 0,
      },
      type: "number",
    },
    {
      Component: ReactSelectInputTwo,
      label: "Category",
      name: "categories",
      registerFields: {
        required: false,
      },
      options: categoryHierarchyList,
      isMultiple: true,
      control: control,
      colClass: "col-xl-12",
    },
    {
      Component: ReactSelectInputTwo,
      label: "Product",
      name: "products",
      registerFields: {
        required: false,
      },
      options: productList,
      isMultiple: true,
      control: control,
      colClass: "col-xl-12",
    },
  ];

  const onChangeRadio = (event) => {
    const value = event.target.value;
    //console.log(value);
    //setSelectedOption(value); // Update local state
    setValue("discount_type", value); // Update the form state
  };

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Add New Coupon"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/coupon", name: "Coupons" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <form onSubmit={handleSubmit(onSubmitNew)}>
            <div className="card card-custom gutter-b">
              {/* <div className="card-header card-header-tabs-line">
                <div className="card-toolbar">
                  <ul
                    className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                    role="tablist"
                  >
                    {languages.length > 0 &&
                      languages.map((lang, index) => (
                        <SubTab
                          key={index}
                          name={lang.name}
                          index={index}
                          image={lang?.image}
                        />
                      ))}
                  </ul>
                </div>
              </div> */}

              <div className="card-body px-0">
                <div className="tab-content px-10">
                  {/* input */}

                  <div className="row">
                    {InputGroup1.map((Input, index) => (
                      <Input.Component
                        key={index}
                        {...Input}
                        errors={errors}
                        register={register}
                        setValue={setValue}
                      />
                    ))}
                  </div>

                  <div className="row">
                    <div class="col-lg-6">
                      <label>Discount Type</label>
                      <span class="text-danger"> </span>
                      <div class="discount-input">
                        <div class="form-group custom_radio">
                          <input
                            type="radio"
                            id="flat"
                            value="flat"
                            // checked={getValues("tax_type") == "Fixed"}
                            {...register("discount_type")}
                            onChange={onChangeRadio}
                          />
                          <label for="Flat" class="m-0">
                            Flat
                          </label>
                        </div>
                        <div class="form-group custom_radio">
                          <input
                            type="radio"
                            id="percentage"
                            value="percentage"
                            // checked={getValues("tax_type") == "Percentage"}
                            {...register("discount_type")}
                            onChange={onChangeRadio}
                          />
                          <label for="percentage" class="m-0">
                            Percentage
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {InputGroup2.map((Input, index) => (
                      <Input.Component
                        key={index}
                        {...Input}
                        errors={errors}
                        register={register}
                        setValue={setValue}
                      />
                    ))}
                  </div>
                </div>

                <button
                  onClick={handleSubmit(onSubmitNew)}
                  style={{ display: "none" }}
                ></button>

                <SubmitButton
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmitNew}
                  name="Add"
                  pxClass="px-10"
                />

                <div className="row"></div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* <DevTool control={control} /> */}
    </div>
  );
};

export default Add;
