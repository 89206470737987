import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { imgArray } from "../../constant/constant";

import {
  Input,
  SelectInput,
  RenderInputFields,
  SubmitButton,
  ReactSelectInput,
  PhoneNumber,
} from "../Form/Form";

const Add = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    control,
  } = useForm();

  const { response, request } = useRequest();
  const { response: responseTitle, request: requestTitle } = useRequest();

  const [titles, setTitles] = useState([]);
  const [image, setImage] = useState("");

  const history = useHistory();

  useEffect(() => {
    if (response) {
      toast.success("Customer has been added successfully.");
      history.push("/customers");
    }
  }, [response]);

  useEffect(() => {
    requestTitle("GET", "master/title-admin");
  }, []);

  useEffect(() => {
    if (responseTitle) {
      const { titles } = responseTitle;
      setTitles(titles);
    }
  }, [responseTitle]);

  const displayImageHandler = (e) => {
    setImage(URL.createObjectURL(e.target.files[0]));
  };
  const handleRemoveMedia = () => {
    setValue("profilePic", "");
    setImage("");
    document.getElementsByName("profilePic")[0].value = "";
  };

  const onSubmit = (data) => {
    let {
      first_name,
      last_name,
      email,
      phone_number,
      phone_number_code,
      profilePic,
      password,
      confirmpassword,
      title,
    } = data;

    let error = 0;

    if (email) {
      email = email.toLowerCase();
    }

    if (password !== confirmpassword) {
      setError("confirmpassword", {
        type: "manual",
      });
      error = 1;
    }

    if (error == 1) {
      return;
    }
    const withoutCountryCode = phone_number.replace(`${phone_number_code}`, "");
    const formData = new FormData();
    formData.append("last_name", last_name);
    formData.append("first_name", first_name);
    formData.append("email", email);
    formData.append("phone_number", withoutCountryCode);
    formData.append("phone_number_code", phone_number_code);
    formData.append("password", password);
    formData.append("title", title);

    if (profilePic && profilePic[0]) {
      const profilePicType = profilePic[0].type;
      if (!imgArray.includes(profilePicType)) {
        toast.error("Please select inage only in profile pic");
        return;
      } else {
        formData.append("profile_picture", profilePic[0]);
      }
    }

    request("POST", "user/create", formData);
  };

  const InputFields = [
    [
      {
        Component: SelectInput,
        label: "Title",
        name: "title",
        registerFields: {
          required: true,
        },
        children: (
          <>
            <option value="">{"Select an option"}</option>
            {titles.map((data) => (
              <option value={data.parent_id} key={data.id}>
                {" "}
                {data.code}
              </option>
            ))}
          </>
        ),
      },
      {
        Component: Input,
        label: "First Name",
        type: "text",
        name: "first_name",
        registerFields: {
          required: true,
          setValueAs: (v) => v.trim(),
          // pattern: /^[A-Za-z ]+$/,
        },
        // registerFieldsFeedback: {
        //   pattern: "First Name can only contain letters.",
        // },
      },
      {
        Component: Input,
        label: "Last Name",
        type: "text",
        name: "last_name",
        registerFields: {
          required: true,
          setValueAs: (v) => v.trim(),
          // pattern: /^[A-Za-z ]+$/,
        },
        // registerFieldsFeedback: {
        //   pattern: "Last Name can only contain letters.",
        // },
      },
      {
        Component: Input,
        label: "Email",
        type: "email",
        name: "email",
        registerFields: {
          required: true,
          pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
          setValueAs: (v) => v.trim(),
        },
        registerFieldsFeedback: {
          pattern: "The email field must be a valid email address.",
        },
      },
      {
        Component: PhoneNumber,
        label: "Phone Number",
        type: "number",
        name: "phone_number",
        control: control,
        setValue: setValue,
        registerFields: {
          required: true,
          pattern: {
            value: /^[0-9][0-9]{7,14}$/,
            // message: "Please enter valid 8-15 digit phone_number number.",
          },
        },
        registerFieldsFeedback: {
          pattern: "Please enter valid 8-15 digit phone number.",
        },
      },

      {
        Component: Input,
        label: "Password",
        type: "password",
        name: "password",
        registerFields: {
          required: true,
          pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
        },
        registerFieldsFeedback: {
          pattern:
            "Password must be of 8 characters long with atleast one uppercase, one lowercase and one number.",
        },
      },
      {
        Component: Input,
        label: "Confirm Password",
        type: "password",
        name: "confirmpassword",
        registerFields: {
          required: true,
          pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
        },
        registerFieldsFeedback: {
          pattern:
            "Confirm password must be of 8 characters long with atleast one uppercase, one lowercase and one number.",
        },
        otherRegisterFields: {
          manual: true,
          feedback: "Confirm password doesn't match with new password",
        },
      },

      {
        Component: Input,
        label: "Profile Pic",
        type: "file",
        name: "profilePic",
        registerFields: {
          required: false,
          setValueAs: (v) => v.trim(),
        },
        registerFieldsFeedback: {
          // pattern: "The zip code field must be a valid zip code.",
        },
        handleMedia: displayImageHandler,
        isMedia: true,
        accept: ".png, .jpg, .jpeg",
        control,
        children: image && (
          <>
            <img
              src={image}
              width={150}
              height={100}
              alt=""
              style={{ cursor: "pointer", marginBottom: "10px" }}
              data-fancybox
            />
            <Link to="#" onClick={handleRemoveMedia} className="mx-3">
              Remove
            </Link>
          </>
        ),
      },
    ],
  ];

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Add Customer"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/agents", name: "Back To Customers" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card card-custom">
            <div class="card-header">
              <h3 class="card-title">Add New Customer</h3>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-xl-12">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <RenderInputFields
                      InputFields={InputFields}
                      errors={errors}
                      register={register}
                    />

                    <div className="row"></div>

                    <SubmitButton
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      name="Submit"
                    />
                  </form>
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
