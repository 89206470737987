import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
// import { SubTab, SubInput } from "./TabNInput";
// import { DevTool } from "@hookform/devtools";
import { useSelector } from "react-redux";

import { API } from "../../constant/api";
import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {
  Input,
  RenderMultiLangInputFields,
  SubmitButton,
  CKEditorInput,
} from "../Form/Form";
import moment from "moment";

const SubTab = ({ name, index, image }) => {
  return (
    <li className={`nav-item ${index > 0 ? "mr-3" : ""}`}>
      <a
        className={`nav-link ${index === 0 ? "active" : ""}`}
        data-toggle="tab"
        href={`#kt_apps_contacts_view_tab_${index}`}
      >
        <>
          {false && image && (
            <span className="symbol symbol-20 mr-3">
              <img src={`${API.PORT}/${image}`} alt="" />
            </span>
          )}
          <span className="nav-text">{name}</span>
        </>
      </a>
    </li>
  );
};

const SubInput = ({ index, children }) => {
  return (
    <div
      className={`tab-pane ${index === 0 ? "active" : ""}`}
      id={`kt_apps_contacts_view_tab_${index}`}
      role="tabpanel"
    >
      {children}
      <div className="row"></div>
    </div>
  );
};

const ProductDetails = (props) => {
  const { id } = props.match.params;
  const ckEditorRef = useRef();

  const { languages } = useSelector((state) => state.setting);
  const history = useHistory();

  const { response, request } = useRequest();
  const [productDetails, setProductDetails] = useState(undefined);

  useEffect(() => {
    document.title = "Product Details - Single Vendor";
    request("GET", `product/details/${id}`);
  }, []);

  useEffect(() => {
    if (!response) return;
    console.log(response);
    setProductDetails(response);
  }, [response]);

  const Tabs = [
    { name: "Product Information" },
    { name: "Product Images" },
    { name: "Product Prices" },
    { name: "Product Variants" },
    // { name: "Product Features" },
  ];

  if (!productDetails) return <></>;

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Product Details"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/product", name: "Product" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card card-custom gutter-b">
            <div className="card-header card-header-tabs-line">
              <div className="card-toolbar">
                <ul
                  className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                  role="tablist"
                >
                  {Tabs.length > 0 &&
                    Tabs.map((tab, index) => (
                      <SubTab
                        key={index}
                        name={tab.name}
                        index={index}
                        image={tab?.image}
                      />
                    ))}
                </ul>
              </div>
            </div>

            <div className="card-body px-0">
              <div className="tab-content px-10">
                <SubInput index={0}>
                  <>
                    <div class="form-group row my-2">
                      <label class="col-4 col-form-label">Name:</label>
                      <div class="col-8">
                        <span class="form-control-plaintext font-weight-bolder">
                          {productDetails.product.name}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row my-2">
                      <label class="col-4 col-form-label">Slug:</label>
                      <div class="col-8">
                        <span class="form-control-plaintext font-weight-bolder">
                          {productDetails.product.slug}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row my-2">
                      <label class="col-4 col-form-label">
                        Parent Category:
                      </label>
                      <div class="col-8">
                        <span class="form-control-plaintext font-weight-bolder">
                          {productDetails.product.parent_category_name}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row my-2">
                      <label class="col-4 col-form-label">
                        Category Level 2:
                      </label>
                      <div class="col-8">
                        <span class="form-control-plaintext font-weight-bolder">
                          {productDetails.product.category_level2_name}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row my-2">
                      <label class="col-4 col-form-label">
                        Category Level 3:
                      </label>
                      <div class="col-8">
                        <span class="form-control-plaintext font-weight-bolder">
                          {productDetails.product.category_level3_name}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row my-2">
                      <label class="col-4 col-form-label">Brand:</label>
                      <div class="col-8">
                        <span class="form-control-plaintext font-weight-bolder">
                          {productDetails.product.brand_name}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row my-2">
                      <label class="col-4 col-form-label">
                        Small Description:
                      </label>
                      <div class="col-8">
                        <span class="form-control-plaintext font-weight-bolder">
                          {productDetails.product.small_description}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row my-2">
                      <label class="col-4 col-form-label">Created On:</label>
                      <div class="col-8">
                        <span class="form-control-plaintext font-weight-bolder">
                          {moment(productDetails.product.created_at).format(
                            "DD-MM-YYYY"
                          )}
                        </span>
                      </div>
                    </div>
                  </>
                </SubInput>
                <SubInput index={1}>
                  <>
                    <div class="form-group row my-2">
                      <label class="col-4 col-form-label">Cover Image:</label>
                      <div class="col-8">
                        <a
                          class="fancybox-buttons"
                          data-fancybox-group="button"
                          href={`${API.PORT}/${productDetails.product.image}`}
                        >
                          <img
                            alt="cover"
                            height="100"
                            width="100"
                            src={`${API.PORT}/${productDetails.product.image}`}
                            style={{ cursor: "pointer" }}
                            data-fancybox
                          />
                        </a>
                      </div>
                    </div>
                    <div class="form-group row my-2">
                      <label class="col-4 col-form-label">Other Images:</label>
                      <div class="col-8 details-img-display-box">
                        {productDetails.images.map((imageDetail) => (
                          <img
                            alt="cover"
                            height="100"
                            width="100"
                            src={`${API.PORT}/${imageDetail.image}`}
                            style={{ cursor: "pointer" }}
                            data-fancybox
                          />
                        ))}
                      </div>
                    </div>
                  </>
                </SubInput>
                <SubInput index={2}>
                  <>
                    <div class="form-group row my-2">
                      <label class="col-4 col-form-label">Price:</label>
                      <div class="col-8">
                        <span class="form-control-plaintext font-weight-bolder">
                          {productDetails.product.price}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row my-2">
                      <label class="col-4 col-form-label">
                        Discount Price:
                      </label>
                      <div class="col-8">
                        <span class="form-control-plaintext font-weight-bolder">
                          {productDetails.product.discount_price}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row my-2">
                      <label class="col-4 col-form-label">
                        Stock Quantity:
                      </label>
                      <div class="col-8">
                        <span class="form-control-plaintext font-weight-bolder">
                          {productDetails.product.stock_qty}
                        </span>
                      </div>
                    </div>
                  </>
                </SubInput>
                <SubInput index={3}>
                  <div class="form-group row my-2">
                    <div class="col-12">
                      <div class="dataTables_wrapper ">
                        <div class="table-responsive">
                          <table class="table dataTable table-head-custom table-head-bg table-borderless table-vertical-center">
                            <thead>
                              <tr>
                                <th>
                                  <a href="javascript:void(0);">Variant</a>
                                </th>
                                <th>
                                  <a href="javascript:void(0);">Price</a>
                                </th>
                                <th>
                                  <a href="javascript:void(0);">
                                    Discount Price
                                  </a>
                                </th>
                                <th>
                                  <a href="javascript:void(0);">Stock Qty</a>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {productDetails.variants.map((variant) => (
                                <tr>
                                  <td>
                                    {variant.color} - {variant.size}
                                  </td>
                                  <td>{variant.price}</td>
                                  <td>{variant.discount_price}</td>
                                  <td>{variant.stock_qty}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </SubInput>
                <SubInput index={4}>
                  <p>d</p>
                </SubInput>
              </div>

              <div className="row"></div>
            </div>
          </div>
        </div>
      </div>
      {/* <DevTool control={control} /> */}
    </div>
  );
};

export default ProductDetails;
