export const API = {
  PORT:
    process.env.NODE_ENV === "development"
      ? "http://192.168.235.245:4035"
      : "https://singlevendorns.stage02.obdemo.com",
};

export const BASEURL = {
  PORT:
    process.env.NODE_ENV === "development"
      ? "http://192.168.235.245:4035"
      : "https://singlevendorns.stage02.obdemo.com",
};

export const FRONTENDURL =
  process.env.NODE_ENV === "development"
    ? "http://192.168.235.245:3000"
    : "https://singlevendorssr.stage02.obdemo.com";
