import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
// import { SubTab, SubInput } from "./TabNInput";
// import { DevTool } from "@hookform/devtools";
import { useSelector } from "react-redux";

import { API } from "../../constant/api";
import useRequest from "../../hooks/useRequest";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {
  Input,
  RenderMultiLangInputFields,
  SubmitButton,
  CKEditorInput,
} from "../Form/Form";
import moment from "moment";

const SubTab = ({ name, index, image }) => {
  return (
    <li className={`nav-item ${index > 0 ? "mr-3" : ""}`}>
      <a
        className={`nav-link ${index === 0 ? "active" : ""}`}
        data-toggle="tab"
        href={`#kt_apps_contacts_view_tab_${index}`}
      >
        <>
          {false && image && (
            <span className="symbol symbol-20 mr-3">
              <img src={`${API.PORT}/${image}`} alt="" />
            </span>
          )}
          <span className="nav-text">{name}</span>
        </>
      </a>
    </li>
  );
};

const SubInput = ({ index, children }) => {
  return (
    <div
      className={`tab-pane ${index === 0 ? "active" : ""}`}
      id={`kt_apps_contacts_view_tab_${index}`}
      role="tabpanel"
    >
      {children}
      <div className="row"></div>
    </div>
  );
};

const ProductDetails = (props) => {
  const { id } = props.match.params;
  const ckEditorRef = useRef();

  const { languages } = useSelector((state) => state.setting);
  const history = useHistory();

  const { response, request } = useRequest();
  const [couponDetails, setCouponDetails] = useState(undefined);

  useEffect(() => {
    document.title = "Coupon Details - Single Vendor";
    request("GET", `coupon/${id}`);
  }, []);

  useEffect(() => {
    if (!response) return;
    console.log(response);
    setCouponDetails(response);
  }, [response]);

  const Tabs = [
    { name: "Discount Code Details" },
    // { name: "Product Images" },
    // { name: "Product Prices" },
    // { name: "Product Variants" },
    // { name: "Product Features" },
  ];

  if (!couponDetails) return <></>;

  return (
    <div
      className="content  d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <Breadcrumb
        title="Coupon Details"
        links={[
          { to: "/", name: "Dashboard" },
          { to: "/coupon", name: "Coupons" },
        ]}
      />

      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <div className="card card-custom gutter-b">
            <div className="card-header card-header-tabs-line">
              <div className="card-toolbar">
                <ul
                  className="nav nav-tabs nav-tabs-space-lg nav-tabs-line nav-bold nav-tabs-line-3x"
                  role="tablist"
                >
                  {Tabs.length > 0 &&
                    Tabs.map((tab, index) => (
                      <SubTab
                        key={index}
                        name={tab.name}
                        index={index}
                        image={tab?.image}
                      />
                    ))}
                </ul>
              </div>
            </div>

            <div className="card-body px-0">
              <div className="tab-content px-10">
                <SubInput index={0}>
                  <>
                    <div class="form-group row my-2">
                      <label class="col-4 col-form-label">Coupon Code:</label>
                      <div class="col-8">
                        <span class="form-control-plaintext font-weight-bolder">
                          {couponDetails.coupon.coupon_code}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row my-2">
                      <label class="col-4 col-form-label">Start Time:</label>
                      <div class="col-8">
                        <span class="form-control-plaintext font-weight-bolder">
                          {moment(couponDetails.coupon.start_time).format(
                            "DD-MM-YYYY"
                          )}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row my-2">
                      <label class="col-4 col-form-label">End Time:</label>
                      <div class="col-8">
                        <span class="form-control-plaintext font-weight-bolder">
                          {moment(couponDetails.coupon.end_time).format(
                            "DD-MM-YYYY"
                          )}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row my-2">
                      <label class="col-4 col-form-label">
                        Number Of Usage:
                      </label>
                      <div class="col-8">
                        <span class="form-control-plaintext font-weight-bolder">
                          {couponDetails.coupon.number_of_usage}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row my-2">
                      <label class="col-4 col-form-label">
                        Number Of Usage Per Person:
                      </label>
                      <div class="col-8">
                        <span class="form-control-plaintext font-weight-bolder">
                          {couponDetails.coupon.number_of_usage_per_person}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row my-2">
                      <label class="col-4 col-form-label">
                        Minimum Product Price:
                      </label>
                      <div class="col-8">
                        <span class="form-control-plaintext font-weight-bolder">
                          {couponDetails.coupon.mininum_product_price}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row my-2">
                      <label class="col-4 col-form-label">
                        Maxiumum Product Price
                      </label>
                      <div class="col-8">
                        <span class="form-control-plaintext font-weight-bolder">
                          {couponDetails.coupon.maxinum_product_price}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row my-2">
                      <label class="col-4 col-form-label">Discount Type:</label>
                      <div class="col-8">
                        <span class="form-control-plaintext font-weight-bolder">
                          {couponDetails.coupon.discount_type}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row my-2">
                      <label class="col-4 col-form-label">Offer Amount:</label>
                      <div class="col-8">
                        <span class="form-control-plaintext font-weight-bolder">
                          {couponDetails.coupon.offer_amount}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row my-2">
                      <label class="col-4 col-form-label">Categories:</label>
                      <div class="col-8">
                        {couponDetails.couponCategories.map((category) => (
                          <span class="form-control-plaintext font-weight-bolder">
                            {category.category_hierarchy}
                          </span>
                        ))}
                      </div>
                    </div>
                    <div class="form-group row my-2">
                      <label class="col-4 col-form-label">Products:</label>
                      <div class="col-8">
                        {couponDetails.couponProducts.map((product) => (
                          <span class="form-control-plaintext font-weight-bolder">
                            {product.name}
                          </span>
                        ))}
                      </div>
                    </div>
                  </>
                </SubInput>
              </div>

              <div className="row"></div>
            </div>
          </div>
        </div>
      </div>
      {/* <DevTool control={control} /> */}
    </div>
  );
};

export default ProductDetails;
